import heroImage1 from '../assets/img/glass-metal.png';
import heroImage2 from '../assets/img/chandelier.png';
import heroImage3 from '../assets/img/motorcycle.png';
import heroImage4 from '../assets/img/piano.png';

import heroImageMobile1 from '../assets/img/glass-metal_mobile.png';
import heroImageMobile2 from '../assets/img/chandelier_mobile.png';
import heroImageMobile3 from '../assets/img/motorcycle_mobile.png';
import heroImageMobile4 from '../assets/img/piano_mobile.png';

export const heroTitles = ['On-site Custom Crating', 'On-site Custom Crating', 'Specialty Services', 'Specialty Services']

export const heroCarouselImages = [
  heroImage1,
  heroImage2,
  heroImage3,
  heroImage4,
];

export const heroCarouselImagesMobile = [
  heroImageMobile1,
  heroImageMobile2,
  heroImageMobile3,
  heroImageMobile4,
];
